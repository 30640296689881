<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Cursos</h4>
            <div class="small text-muted">Administración de cursos</div>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col md="9">
          <b-row>
            <b-col md="4">                         
              <Widget :configWidget="configWidget" reference="cant_courses" :hasAccess="configWidget.elements.cantCourses" />
            </b-col>
            <b-col md="4">                         
              <Widget :configWidget="configWidget" reference="list_subcategories" :hasAccess="configWidget.elements.listSubcategories" />            
            </b-col>
            <b-col md="8"></b-col>
          </b-row>
        </b-col>  
        <b-col md="3">        
          
          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openCourses()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Cursos</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de cursos.
              </p>
            </b-list-group-item>
          </b-list-group>                           
        
          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openCategories()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Categorias</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de categorias y sub-categorias
              </p>
            </b-list-group-item>
          </b-list-group> 

        </b-col>        
      </b-row>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import Widget from '@/components/inc/widget/widget'
  import Param from '@/config/parameters'

  export default {
    components: {
      Widget
    },
    data: () => {
      return {
        access: {
          module_id: Modules.CURSOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'dashboard',
          elements: {}
        },        
        configWidget: {
          module_id: Modules.CURSOS,
          profile_id: Profiles.PERSONAL,
          elements: {             
            cantProducts: true,         
            listSubcategories: true,
          }
        },        
        primaryColor: '',
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */

      /* Configurar permisos de los widgets */      
      this.configWidget.elements.cantCourses = Helper.hasAccessWidget(this.configWidget, 'cant_courses')            
      this.configWidget.elements.listSubcategories = Helper.hasAccessWidget(this.configWidget, 'list_subcategories')      
      /* Fin configuracion */
    },
    mounted() {
      this.setup()
    }, 
    methods: {
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary
                    
          this.createCSS()
        }
      },         
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""      
        hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover, .list-group-item-action:focus { background-color: " + this.primaryColor + "; }"

        document.body.appendChild(hoja);
      }, 
      
      openCourses() {
        this.$router.push({ name: 'CoursesStaffCrudCourses' })
      },
      
      openCategories() {
        this.$router.push({ name: 'CoursesStaffCrudCategories' })
      },
           
    }    
   
  }
</script>

<style>

</style>
